// src/components/Spinner.tsx

import React from 'react';

export interface SpinnerProps {
  /** Largeur/hauteur en px (ex: 40 = 40px). */
  size?: number;
  /** Épaisseur de la bordure en px */
  borderWidth?: number;
  /** Couleur principale, ex: 'blue-500' */
  color?: string;
  /** Classes supplémentaires si besoin */
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  size = 40,
  borderWidth = 4,
  color = 'blue-500',
  className = '',
}) => {
  // Style inline : on applique la largeur/hauteur + l’épaisseur
  const style: React.CSSProperties = {
    width: `${size}px`,
    height: `${size}px`,
    borderWidth: `${borderWidth}px`,
  };

  return (
    <div
      className={`
        inline-block
        rounded-full
        border-t-transparent
        animate-spin
        border-${color}
        ${className}
      `}
      style={style}
      role="status"
      aria-label="Loading"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};