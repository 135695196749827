// src/services/StripeService.ts

import { Stripe } from '@capacitor-community/stripe';

export class StripeService {
  static async initialize() {
    // ─────────────────────────────────────────────────────────────────────
    // Ajout d'un log pour vérifier la clé publique (10 premiers caractères)
    // ─────────────────────────────────────────────────────────────────────
    console.log(
      '[StripeService] Publishable key =>',
      (process.env.REACT_APP_STRIPE_PUBLIC_KEY || '').substring(0, 10) + '...'
    );

    try {
      await Stripe.initialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
      });
      console.log('[StripeService] Stripe initialisé avec succès');
    } catch (error) {
      console.error('[StripeService] Erreur lors de l\'initialisation de Stripe:', error);
    }
  }
}