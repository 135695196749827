// src/i18n.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import du backend personnalisé
import LocalBackend from './localBackend';

/**
 * 1) Liste des langues supportées
 */
const languages = [
  'ar', 'de', 'en', 'es', 'fr', 'hi', 'it', 'ja', 'ko',
  'nl', 'pl', 'pt', 'ru', 'tr', 'zh', 'id', 'ur'
];

/**
 * 2) Liste des namespaces utilisés dans le projet.
 */
const namespaces = [
  'common',
  'landingPage',
  'scenarios',
  'ScenarioPhrasesPage',
  'ScenarioDetailsPage',
  'registerEmailForm',
  'login',
  'paywall',
  'successPage',
  'cancelPage',
  'consolePage',
  'continueOrSwitchOverlay',
  'scenarioCompletionOverlay',
  'streakCompletionOverlay',
  'realtime',
  'languages',
  'contactPage',
  'aboutPage',
  'pricingPage',
  'howItWorksPage',
  'icebreaker',
  'settingsPage',
  'heardAboutPage',
  'why-learn',
  'encouragement',
  'daily-goal',
  'start-choice',
  'missionVisionPage',
  'notificationsPage',
  'footer',
  'streakObjectiveOverlay',
  'PronunciationBlock',
  'LandingPromoBar',
  'TrialEndReminderPage',
  'PlanSelectionPage',
  'accountLinking',
  'deleteConversationsPage', // Namespace existant
  'DeleteAccountPage'        // Namespace ajouté pour DeleteAccountPage
];

/**
 * 3) Configuration d'i18next avec le backend personnalisé
 */
i18n
  // Notre backend custom (import dynamique)
  .use(LocalBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    supportedLngs: languages,
    ns: namespaces,
    defaultNS: 'common',
    fallbackNS: 'common',
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag', 'cookie', 'path', 'subdomain'],
      caches: ['localStorage']
    },
    saveMissing: false,
    returnObjects: false,
    react: {
      useSuspense: true
    },
    returnEmptyString: false
  });

export default i18n;