// src/localBackend.ts

import type { BackendModule, ReadCallback, CallbackError } from 'i18next';

/**
 * Backend i18n qui charge chaque fichier de traduction
 * via `import()` dynamique => code-splitting.
 */
const LocalBackend: BackendModule = {
  type: 'backend',
  
  init(services, backendOptions, i18nextOptions) {
    // Pas besoin d'initialisation spéciale
  },

  async read(language, namespace, callback: ReadCallback) {
    try {
      // Le commentaire webpackChunkName sépare les chunks par "i18n-<lang>-<namespace>"
      const resource = await import(
        /* webpackChunkName: "i18n-[request]" */
        `./locales/${language}/${namespace}.json`
      );
      callback(null, resource.default);
    } catch (error) {
      // On s'assure que l'erreur est bien de type Error
      const err: CallbackError = error instanceof Error ? error : new Error(String(error));
      console.error(`[LocalBackend] Erreur de chargement pour ${language}/${namespace}`, err);
      callback(err, false);
    }
  },

  create(languages, namespace, key, fallbackValue) {
    // Non implémenté
  },
};

export default LocalBackend;