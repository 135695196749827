// src/components/ToastContainer.tsx

import React from 'react';
import { useToast } from '../context/ToastContext';

export const ToastContainer: React.FC = () => {
  const { toasts } = useToast();

  return (
    <div className="fixed top-16 left-1/2 -translate-x-1/2 z-[9999] pt-safe flex flex-col space-y-2">
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`
            px-4 py-3 
            voxa-p
            rounded 
            border-2 
            shadow-md 
            font-semibold 
            text-center
            ${
              toast.type === 'success'
                ? 'bg-green-200 border-green-700 text-green-900'
                : toast.type === 'error'
                ? 'bg-red-200 border-red-700 text-red-900'
                : 'bg-blue-200 border-blue-700 text-blue-900'
            }
          `}
          style={{ minWidth: 300, margin: '0 auto' }}
        >
          {toast.message}
        </div>
      ))}
    </div>
  );
};