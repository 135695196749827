// src/config/apiUrl.ts

import { Capacitor } from '@capacitor/core';
import { useState, useEffect } from 'react';

// URL de base de l'API et port
const API_PORT = '8082';

// Variables globales pour la session
let hasLoggedIpWarning = false;
let localIpAddress: string | null = null;

/**
 * Fonction pour détecter automatiquement l'IP locale (iOS dev),
 * et la stocker 24h dans localStorage.
 */
async function detectLocalIpAddress(): Promise<string | null> {
  // Vérifier d'abord le localStorage
  const cachedIp = localStorage.getItem('localApiIpAddress');
  const cacheTimestamp = localStorage.getItem('localApiIpAddressTimestamp');
  
  // Utiliser l'IP en cache si elle existe et date de moins de 24h
  if (cachedIp && cacheTimestamp) {
    const cacheAge = Date.now() - parseInt(cacheTimestamp, 10);
    if (cacheAge < 24 * 60 * 60 * 1000) {
      localIpAddress = cachedIp;
      return cachedIp;
    }
  }
  
  // Si pas de cache valide, on tente une détection
  try {
    const controller = new AbortController();
    const timer = setTimeout(() => controller.abort(), 3000);
    try {
      const response = await fetch(`http://localhost:${API_PORT}/api/detect-ip`, {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' },
        signal: controller.signal,
      });
      if (response.ok) {
        const data = await response.json();
        if (data.localIp) {
          localStorage.setItem('localApiIpAddress', data.localIp);
          localStorage.setItem('localApiIpAddressTimestamp', Date.now().toString());
          console.log(`[apiUrl] ✅ IP locale détectée automatiquement: ${data.localIp}`);
          localIpAddress = data.localIp;
          return data.localIp;
        }
      }
    } finally {
      clearTimeout(timer);
    }
  } catch (err) {
    console.warn('[apiUrl] Impossible de détecter automatiquement l\'IP locale');
  }
  
  // Fallback sur null
  return null;
}

/**
 * Fonction principale asynchrone pour récupérer l’URL d’API.
 */
export const getApiUrl = async (): Promise<string> => {
  const platform = Capacitor.getPlatform();           // ex: 'ios', 'android', 'web'
  const isNative = Capacitor.isNativePlatform();      // true si iOS/Android (capacitor)
  const isDevelopment = process.env.NODE_ENV !== 'production';

  // 1) CAS PRODUCTION (web + mobile)
  if (!isDevelopment) {
    return 'https://www.voxanet.com';
  }

  // 2) CAS DÉVELOPPEMENT NATIF
  if (isNative) {
    // Android en dev
    if (platform === 'android') {
      return `http://10.0.2.2:${API_PORT}`;
    }

    // iOS en dev
    if (platform === 'ios') {
      const detectedIp = await detectLocalIpAddress();
      if (detectedIp) {
        return `http://${detectedIp}:${API_PORT}`;
      }

      // Sinon, avertissement
      if (!hasLoggedIpWarning) {
        console.log('[apiUrl] ⚠️ Utilisation de localhost pour iOS - risque de ne pas fonctionner');
        console.log('[apiUrl] ⚠️ Ajoutez une route /api/detect-ip à votre serveur');
        hasLoggedIpWarning = true;
      }
      return `http://localhost:${API_PORT}`;
    }
  }

  // 3) CAS DÉVELOPPEMENT WEB
  // (Pas natif, isDevelopment=true => web local)
  return `http://localhost:${API_PORT}`;
};

/**
 * Version synchrone (pour usage dans du code synchrone)
 */
export const getApiUrlSync = (): string => {
  const platform = Capacitor.getPlatform();
  const isNative = Capacitor.isNativePlatform();
  const isDevelopment = process.env.NODE_ENV !== 'production';

  // 1) Prod
  if (!isDevelopment) {
    return 'https://www.voxanet.com';
  }

  // 2) Dev natif
  if (isNative) {
    if (platform === 'android') {
      return `http://10.0.2.2:${API_PORT}`;
    }
    if (platform === 'ios') {
      // On ignore la détection IP en sync (pas possible)
      return localIpAddress
        ? `http://${localIpAddress}:${API_PORT}`
        : `http://localhost:${API_PORT}`;
    }
  }

  // 3) Dev web
  return `http://localhost:${API_PORT}`;
};

/**
 * Génère la WebSocket URL asynchrone en remplaçant le préfixe http=>ws
 */
export const getWebSocketUrl = async (): Promise<string> => {
  const baseUrl = await getApiUrl();
  return baseUrl.replace(/^http:\/\//, 'ws://').replace(/^https:\/\//, 'wss://');
};

/**
 * Version synchrone pour WebSocket
 */
export const getWebSocketUrlSync = (): string => {
  const baseUrl = getApiUrlSync();
  return baseUrl.replace(/^http:\/\//, 'ws://').replace(/^https:\/\//, 'wss://');
};

/**
 * Hook React pour récupérer l’API URL, avec mise à jour asynchrone en mount.
 */
export function useApiUrl() {
  // Valeur initiale synchrone
  const [apiUrl, setApiUrl] = useState<string>(getApiUrlSync());

  useEffect(() => {
    let isMounted = true;
    async function fetchApiUrl() {
      try {
        const url = await getApiUrl();
        if (isMounted) {
          setApiUrl(url);
        }
      } catch (error) {
        console.warn('[useApiUrl] Erreur lors de la récupération de l\'URL API:', error);
      }
    }
    fetchApiUrl();

    return () => {
      isMounted = false;
    };
  }, []);

  return apiUrl;
}
