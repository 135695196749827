// src/index.tsx

import React from 'react';
import ReactDOM from 'react-dom/client';

// Import du CSS Tailwind et des styles
import './App.scss'; // IMPORTANT : Charger Tailwind au tout début
import './i18n';     // Config i18n

import App from './App';
import reportWebVitals from './reportWebVitals';

import { AuthProvider } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import { StudyProvider } from './context/StudyContext';
import { TotalMessageCountProvider } from './context/TotalMessageCountContext';
import { UserProvider } from './context/UserContext';

import { ToastProvider } from './context/ToastContext';
import { ToastContainer } from './components/ToastContainer';

// Import des drapeaux
import 'flag-icons/css/flag-icons.min.css';

/* === LOGS D'ENVIRONNEMENT === */
console.log('[EnvCheck] => process.env.NODE_ENV:', process.env.NODE_ENV);
console.log('[EnvCheck] => process.env.REACT_APP_ENABLE_FACEBOOK:', process.env.REACT_APP_ENABLE_FACEBOOK);
/* ============================ */

/* ------------------------------------------------------------------
 *  Rendu principal
 * ----------------------------------------------------------------*/
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Composant d'erreur (en fallback du ErrorBoundary Sentry)
const ErrorFallback = () => (
  <div className="flex items-center justify-center h-full bg-transparent">
    <div className="text-center p-4">
      <h2 className="text-xl font-bold mb-2">An error has occurred</h2>
      <p className="text-gray-600 mb-4">
        The application is experiencing an issue. Please refresh the page.
      </p>
      <button
        onClick={() => window.location.reload()}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
      >
        Refresh page
      </button>
    </div>
  </div>
);

// Tout l'arbre de Providers
const AppWithProviders = () => (
  <ToastProvider>
    <ToastContainer />
    <AuthProvider>
      <UserProvider>
        <LanguageProvider>
          <StudyProvider>
            <TotalMessageCountProvider>
              <App />
            </TotalMessageCountProvider>
          </StudyProvider>
        </LanguageProvider>
      </UserProvider>
    </AuthProvider>
  </ToastProvider>
);

/* ------------------------------------------------------------------
 *  2. Fonctions d'init Sentry (asynchrone) et de render
 * ----------------------------------------------------------------*/

// Rendu sans Sentry (pour dev, ou si Sentry fail).
function renderWithoutSentry() {
  root.render(
    <React.StrictMode>
      <AppWithProviders />
    </React.StrictMode>
  );

  reportWebVitals(console.log);
}

// Rendu *avec* Sentry (une seule fois), on fait un import dynamique
async function renderWithSentry() {
  try {
    const Sentry = await import('@sentry/react');

    // Configuration Sentry
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        // Replay Integration si besoin
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      environment: process.env.NODE_ENV,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/voxanet\.herokuapp\.com/,
        /^https:\/\/voxanet-staging\.herokuapp\.com/,
      ],
      beforeSend(event, hint) {
        // Ignore les erreurs "Permission denied" micro/camera
        const eventMessage = event.message || '';
        const exceptionValue = event.exception?.values?.[0]?.value || '';
        const exceptionMessage =
          hint?.originalException instanceof Error
            ? hint.originalException.message
            : '';

        if (
          eventMessage.includes('Permission denied') ||
          eventMessage.includes('Connection failed: Permission denied') ||
          exceptionValue.includes('Permission denied') ||
          exceptionMessage.includes('Permission denied') ||
          exceptionMessage.includes('Connection failed: Permission denied')
        ) {
          console.log('[Sentry] Erreur de permission micro ignorée:', eventMessage || exceptionValue);
          return null;
        }

        return {
          ...event,
          tags: {
            ...event.tags,
            locale: navigator.language,
            viewport: `${window.innerWidth}x${window.innerHeight}`,
          },
        };
      },
      maxBreadcrumbs: 50,
      debug: false,
    });

    // Rendu avec Sentry.ErrorBoundary
    root.render(
      <Sentry.ErrorBoundary
        fallback={<ErrorFallback />}
        onError={(error) => {
          console.error('[Sentry.ErrorBoundary] Caught error:', error);
        }}
      >
        <React.StrictMode>
          <AppWithProviders />
        </React.StrictMode>
      </Sentry.ErrorBoundary>
    );

    reportWebVitals(({ name, value, id }) => {
      Sentry.addBreadcrumb({
        category: 'performance',
        message: `${name}: ${value}`,
        level: 'info',
        data: { id },
      });
    });

    console.log('[Sentry] Initialized successfully');
  } catch (error) {
    console.error('[Sentry] Failed to initialize:', error);
    renderWithoutSentry();
  }
}

/* ------------------------------------------------------------------
 *  3. Logique finale
 * ----------------------------------------------------------------*/
if (process.env.NODE_ENV === 'production') {
  // On fait un import "lazy" de Sentry, SANS double rendu.
  renderWithSentry(); // Laisse le faire direct
} else {
  console.log('[Sentry] Skipped in development');
  renderWithoutSentry();
}