// src/hooks/useAppInitialization.ts

import { useEffect, useState } from 'react';
import { auth } from '../firebaseConfig';
import { useUser } from '../context/UserContext';
import { useStudy } from '../context/StudyContext';
import { getApiUrl } from '../config/apiUrl';
import { WorkflowStep } from '../workflow/WorkflowManager';

type UseAppInitializationParams = {
  currentStep: WorkflowStep;
  setCurrentStep: React.Dispatch<React.SetStateAction<WorkflowStep>>;
  locationPathname: string;
};

/**
 * Hook d'initialisation « non-bloquante » :
 *   - Surveille onAuthStateChanged => si user => refreshUserData
 *   - Optionnellement, on modifie currentStep si on veut skip le onboarding
 *     en se basant sur la DB (si l'user a déjà choisi une langue, etc.)
 *   - NE bloque PAS le rendu : la LandingPage s'affiche immédiatement.
 */
export function useAppInitialization({
  currentStep,
  setCurrentStep,
  locationPathname,
}: UseAppInitializationParams) {
  const [authChecking, setAuthChecking] = useState(true);
  const [userDataLoading, setUserDataLoading] = useState(true);

  const { refreshUserData } = useUser();
  const { setStudyLanguage, setStudyLevel } = useStudy();

  useEffect(() => {
    console.log('[useAppInitialization] => Attaching onAuthStateChanged | Initial currentStep:', currentStep);
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log('[useAppInitialization] => onAuthStateChanged => user=', user?.uid || null, `| isAnonymous: ${user?.isAnonymous}`);
      if (user) {
        setUserDataLoading(true);
        try {
          console.log('[useAppInitialization] => Refreshing userData pour user:', user.uid);
          await refreshUserData();

          // On peut charger la langue/niveau depuis la DB
          console.log('[useAppInitialization] => Fetching languageInfo pour user:', user.uid);
          const languageInfo = await fetchUserLanguageInfo(user.uid);
          console.log('[useAppInitialization] => languageInfo récupéré =>', JSON.stringify(languageInfo));

          // On charge simplement les valeurs dans les contextes sans rediriger
          if (languageInfo.lastSelectedStudyLanguage) {
            console.log('[useAppInitialization] => lastSelectedStudyLanguage détecté:', languageInfo.lastSelectedStudyLanguage);
            setStudyLanguage(languageInfo.lastSelectedStudyLanguage);
          }
          
          if (languageInfo.lastSelectedStudyLevel != null) {
            console.log('[useAppInitialization] => lastSelectedStudyLevel détecté:', languageInfo.lastSelectedStudyLevel);
            setStudyLevel(languageInfo.lastSelectedStudyLevel);
          }
          
          // Ne plus faire de setCurrentStep ou de navigation ici
          // MegaRouteGuard s'en chargera
          
        } catch (err) {
          console.error('[useAppInitialization] refreshUserData => ERREUR DÉTAILLÉE:', err);
          console.error('[useAppInitialization] => stack trace:', err instanceof Error ? err.stack : 'No stack available');
        }
        setUserDataLoading(false);
      } else {
        // Pas de user
        setUserDataLoading(false);
      }
      setAuthChecking(false);
    });

    return () => {
      console.log('[useAppInitialization] => cleaning up onAuthStateChanged listener');
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ex. surveillance du path marketing
  useEffect(() => {
    const path = locationPathname.toLowerCase();
    const isMarketing = ['/about','/contact','/pricing','/how-it-works'].some((k) => path.includes(k));
    if (isMarketing) {
      console.log('[useAppInitialization] => marketing path détecté =>', path);
    } else {
      console.log('[useAppInitialization] => path changé =>', path);
    }
  }, [locationPathname]);

  return {
    authChecking,
    userDataLoading,
  };
}

async function fetchUserLanguageInfo(uid: string) {
  console.log('[fetchUserLanguageInfo] => DÉBUT fetch pour uid:', uid);
  const apiUrl = await getApiUrl();
  try {
    const url = `${apiUrl}/api/user/getUserProfileInfo?uid=${uid}`;
    console.log('[fetchUserLanguageInfo] => Calling API:', url);
    const resp = await fetch(url);
    console.log('[fetchUserLanguageInfo] => Status response:', resp.status, resp.statusText);
    
    if (!resp.ok) {
      console.error('[fetchUserLanguageInfo] => HTTP error:', resp.status, resp.statusText);
      return {};
    }
    
    const data = await resp.json();
    console.log('[fetchUserLanguageInfo] => Données récupérées:', JSON.stringify(data));
    return data;
  } catch (err) {
    console.error('[fetchUserLanguageInfo] => ERREUR DÉTAILLÉE:', err);
    console.error('[fetchUserLanguageInfo] => stack trace:', err instanceof Error ? err.stack : 'No stack available');
    return {};
  }
}