// /voxanet/src/utils/logger.js
import * as Sentry from '@sentry/react';

/**
 * On définit explicitement le niveau de log à "debug"
 * pour afficher toutes les traces pendant le développement.
 */
function levelToPriority(lvl) {
  switch (lvl) {
    case 'debug': return 0;
    case 'info':  return 1;
    case 'warn':  return 2;
    case 'error': return 3;
    default:      return 0;
  }
}

// Forcer l'affichage de tous les logs (debug)
const envLevel = 'debug'; 
const currentPriority = levelToPriority(envLevel);

function shouldLog(requested) {
  return levelToPriority(requested) >= currentPriority;
}

export const logger = {
  debug: (...args) => {
    if (shouldLog('debug')) {
      console.debug('[DEBUG]', ...args);
    }
  },
  info: (...args) => {
    if (shouldLog('info')) {
      console.info('[INFO]', ...args);
    }
  },
  warn: (...args) => {
    if (shouldLog('warn')) {
      console.warn('[WARN]', ...args);
    }
  },
  error: (error, context) => {
    if (shouldLog('error')) {
      console.error('[ERROR]', error, context);
    }
    if (process.env.NODE_ENV === 'production') {
      const errObj = error instanceof Error ? error : new Error(String(error));
      Sentry.captureException(errObj, { extra: context });
    }
  },
};