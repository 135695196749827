// src/context/UserContext.tsx

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getApiUrl } from '../config/apiUrl';
import { useAuth } from './AuthContext';

/**
 * Représente une conversation (optionnel : ajustez selon votre schéma réel).
 */
export interface ConversationData {
  startedAt?: string;
  lastMessageAt?: string;
  messages?: any[]; // Remplacer `any[]` par le type exact de vos messages si nécessaire
  messageCount?: number;
  duration?: number;
}

/**
 * Représente un scénario (ScenarioData), incluant .conversations
 */
export interface ScenarioData {
  scenarioId: string;
  completed?: boolean;
  conversations?: ConversationData[];
}

/**
 * Représente une langue d'étude, avec la liste de scénarios
 */
export interface StudyLanguageData {
  language: string;
  level?: number;
  totalConversations?: number;
  scenarios?: ScenarioData[];
}

/**
 * Représente l'ensemble des données utilisateur renvoyées par getUserProfileInfo
 */
export interface UserData {
  browserLanguage?: string;
  interfaceLanguage?: string;
  lastSelectedStudyLanguage?: string;
  lastSelectedStudyLevel?: number;
  studyLanguages?: StudyLanguageData[];

  subscriptionEndDate?: string;
  subscriptionStartDate?: string; // Optionnel
  isPayingMember?: boolean;       // Indique si l'utilisateur est abonné
  email?: string;
  isAnonymous?: boolean;
  dailyStreak?: number;           // Streak quotidienne
  longestStreak?: number;         // Streak la plus longue
  streakMessagesToday?: number;   // Nombre de messages envoyés aujourd'hui

  /**
   * Nouveau champ pour la gestion du streak :
   * Date du dernier message validant la streak, sous forme de string (ISO).
   */
  lastStreakDate?: string;

  /**
   * Nouveau champ pour la gestion du streak :
   * Date de début de la streak en cours, sous forme de string (ISO).
   */
  startStreakDate?: string;

  /**
   * Nouveau champ pour gérer le prénom.
   * On le déclare en optionnel pour éviter des erreurs si jamais il n'est pas défini côté backend.
   */
  firstName?: string;
}

/**
 * Type du contexte utilisateur
 */
interface UserContextProps {
  userData: UserData | null;
  refreshUserData: () => Promise<void>;
  setUserDataDirectly: (data: UserData) => void;
  loading: boolean;
}

/**
 * Création du contexte
 */
const UserContext = createContext<UserContextProps | undefined>(undefined);

console.log('[UserContext] (NOUVEAU LOG) Création du UserProvider');

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const setUserDataDirectly = useCallback((data: UserData) => {
    console.log('[UserContext] Setting user data directly from updateUserInfo response');
    setUserData(data);
  }, []);

  /**
   * refreshUserData :
   *  - Récupère (via /api/user/getUserProfileInfo) les données utilisateur
   *  - Met à jour userData dans le state
   */
  const refreshUserData = useCallback(async () => {
    const uid = currentUser?.uid;
    if (!uid) {
      setUserData(null);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      console.log('[UserContext] Fetching user data for UID:', uid);
      
      const apiUrl = await getApiUrl();
      const url = `${apiUrl}/api/user/getUserProfileInfo?uid=${uid}`;
      
      const response = await fetch(url);
      
      if (!response.ok) {
        console.error('[UserContext] Echec fetch user data:', response.status, response.statusText);
        throw new Error(`Failed to fetch user data: ${response.status} ${response.statusText}`);
      }
      
      const data: UserData = await response.json();
      
      console.log('[UserContext] User data fetched successfully');
      
      setUserData(data);
    } catch (error) {
      console.error('[UserContext] Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  // useEffect pour surveiller currentUser et déclencher refreshUserData
  useEffect(() => {
    if (currentUser) {
      console.log('[UserContext] Current user detected:', currentUser.uid);
      console.log('[UserContext] (NOUVEAU LOG) Appel refreshUserData() car currentUser existe');
      refreshUserData();
    } else {
      console.log('[UserContext] No current user detected => set userData=null');
      setUserData(null);
      setLoading(false);
    }
  }, [currentUser, refreshUserData]);

  return (
    <UserContext.Provider value={{ 
      userData, 
      refreshUserData, 
      setUserDataDirectly,
      loading
    }}>
      {children}
    </UserContext.Provider>
  );
};

/**
 * Hook personnalisé pour consommer le contexte UserContext
 */
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};