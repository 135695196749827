// src/services/RevenueCatService.ts

import { Device } from '@capacitor/device';
import { Purchases } from '@revenuecat/purchases-capacitor';

let isConfigured = false;

export async function initRevenueCat(appUserID?: string) {
  if (isConfigured) return;

  const info = await Device.getInfo();
  const platform = info.platform; // 'ios' | 'android' | 'web'

  // Remplace par tes clés RevenueCat
  // (Dans le tableau de bord RevenueCat : "appl_xxx" pour iOS, "goog_xxx" pour Android)
  let apiKey = 'appl_FPaTzQSOoDqoWksGvymSjbQopvs';
  if (platform === 'android') {
    apiKey = 'goog_XXXX';
  }

  // Utilisation de appUserID (avec D majuscule)
  await Purchases.configure({ apiKey, appUserID });
  isConfigured = true;
  console.log('[RevenueCat] configuré avec succès');
}