// src/context/StudyContext.tsx

import React, { createContext, useState, useContext } from 'react';

export const STUDY_LEVELS = [0, 1, 2, 3, 4];

interface StudyContextType {
  studyLanguage: string;
  setStudyLanguage: (language: string) => void;
  studyLevel: number;
  setStudyLevel: (level: number) => void;
  selectedScenario: string;
  setSelectedScenario: (scenario: string) => void;
}

// Conserver ce log initial
console.log('[StudyContext] Initialisation du contexte');

const StudyContext = createContext<StudyContextType | undefined>(undefined);

export const StudyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Supprimer le log avant useState
  // console.log('[StudyContext] (NOUVEAU LOG) Avant useState pour studyLanguage, studyLevel, selectedScenario');

  const storedLang = localStorage.getItem('studyLanguage') || '';
  const [studyLanguage, _setStudyLanguage] = useState<string>(storedLang);
  const [studyLevel, _setStudyLevel] = useState<number>(0);
  const [selectedScenario, _setSelectedScenario] = useState<string>('');

  // Conserver ce log initial (utile pour débogage)
  console.log('[StudyContext] État initial:', {
    studyLanguage,
    studyLevel,
    selectedScenario,
  });

  const handleSetStudyLanguage = (language: string) => {
    // Garder uniquement le log significatif
    console.log('[StudyContext] setStudyLanguage:', language);
    _setStudyLanguage(language);
    localStorage.setItem('studyLanguage', language);
  };

  const handleSetStudyLevel = (level: number) => {
    console.log('[StudyContext] setStudyLevel:', level);
    _setStudyLevel(level);
  };

  const handleSetSelectedScenario = (scenario: string) => {
    console.log('[StudyContext] setSelectedScenario:', scenario);
    _setSelectedScenario(scenario);
  };

  // Supprimer le log avant le return
  // console.log('[StudyContext] (NOUVEAU LOG) Juste avant le return =>', {...});

  return (
    <StudyContext.Provider
      value={{
        studyLanguage,
        setStudyLanguage: handleSetStudyLanguage,
        studyLevel,
        setStudyLevel: handleSetStudyLevel,
        selectedScenario,
        setSelectedScenario: handleSetSelectedScenario,
      }}
    >
      {children}
    </StudyContext.Provider>
  );
};

export function useStudy() {
  const context = useContext(StudyContext);
  if (!context) {
    throw new Error('useStudy must be used within a StudyProvider');
  }
  return context;
}