// src/context/ToastContext.tsx

import React, {
    createContext,
    useContext,
    useState,
    useCallback,
    useMemo,
    ReactNode
  } from 'react';
  
  /**
   * Définition d'un toast minimal :
   * - id : identifiant unique pour pouvoir le retirer facilement
   * - type : "success", "error", "info" (ou autre si besoin)
   * - message : le texte à afficher
   */
  type ToastType = 'success' | 'error' | 'info';
  
  interface Toast {
    id: number;
    type: ToastType;
    message: string;
  }
  
  /**
   * Le contexte renverra :
   * - la liste de tous les toasts
   * - la fonction showToast pour en créer un
   * - la fonction hideToast pour en retirer un (si besoin)
   */
  interface ToastContextProps {
    toasts: Toast[];
    showToast: (message: string, type?: ToastType, duration?: number) => void;
    hideToast: (id: number) => void;
  }
  
  const ToastContext = createContext<ToastContextProps | undefined>(undefined);
  
  // compteur interne pour donner un id unique à chaque toast
  let toastIdCounter = 0;
  
  /**
   * ToastProvider : englobe l'application et gère l'état des toasts
   */
  export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);
  
    /**
     * showToast : crée un nouveau toast et l'ajoute au tableau
     * @param message 
     * @param type (default = 'success')
     * @param duration (temps d'affichage en ms, default = 5000)
     */
    const showToast = useCallback(
      (message: string, type: ToastType = 'success', duration = 5000) => {
        const newToast: Toast = {
          id: ++toastIdCounter,
          type,
          message,
        };
        setToasts((prev) => [...prev, newToast]);
  
        // Supprime automatiquement le toast après `duration` ms
        setTimeout(() => {
          setToasts((prevToasts) => prevToasts.filter((t) => t.id !== newToast.id));
        }, duration);
      },
      []
    );
  
    /**
     * hideToast : enlève un toast manuellement (si nécessaire)
     */
    const hideToast = useCallback((id: number) => {
      setToasts((prev) => prev.filter((t) => t.id !== id));
    }, []);
  
    const contextValue = useMemo(
      () => ({ toasts, showToast, hideToast }),
      [toasts, showToast, hideToast]
    );
  
    return (
      <ToastContext.Provider value={contextValue}>
        {children}
      </ToastContext.Provider>
    );
  };
  
  /**
   * Hook pour accéder au contexte
   */
  export const useToast = (): ToastContextProps => {
    const context = useContext(ToastContext);
    if (!context) {
      throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
  };