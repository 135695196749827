// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
// Imports spécifiques d'authentification uniquement
import { getAuth, initializeAuth, indexedDBLocalPersistence } from "firebase/auth";
import { Capacitor } from '@capacitor/core';

// Détection de la plateforme
const platform = Capacitor.getPlatform(); // 'ios', 'android', ou 'web'
const isNative = Capacitor.isNativePlatform();

// Configurations spécifiques à chaque plateforme
const webConfig = {
  apiKey: "AIzaSyCGwnFcn5o_9GXjjb75D8rszhxfv_j01qM",
  authDomain: "nouveau-voxanet-2a5e7.firebaseapp.com",
  projectId: "nouveau-voxanet-2a5e7",
  storageBucket: "nouveau-voxanet-2a5e7.appspot.com",
  messagingSenderId: "722747874132",
  appId: "1:722747874132:web:aaf9d82a49a409bdebc6c1",
  measurementId: "G-Y7SE2YDVRH"
};

const iosConfig = {
  apiKey: "AIzaSyCTIk8yi3vnu1tOUGFVrH4oh_ZRxzIP1XY",
  authDomain: "nouveau-voxanet-2a5e7.firebaseapp.com",
  projectId: "nouveau-voxanet-2a5e7",
  storageBucket: "nouveau-voxanet-2a5e7.appspot.com",
  messagingSenderId: "722747874132",
  appId: "1:722747874132:ios:70e0148a0fda056cebc6c1"
};

// Pour Android, utilisez la même configuration que le web ou créez une configuration spécifique
const androidConfig = webConfig;

// Sélection de la configuration appropriée
let firebaseConfig;
if (platform === 'ios') {
  console.log('[Firebase] Utilisation de la configuration iOS');
  firebaseConfig = iosConfig;
} else if (platform === 'android') {
  console.log('[Firebase] Utilisation de la configuration Android');
  firebaseConfig = androidConfig;
} else {
  console.log('[Firebase] Utilisation de la configuration Web');
  firebaseConfig = webConfig;
}

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Firebase Authentication avec la persistance appropriée pour les plateformes natives
export const auth = isNative
  ? initializeAuth(app, { persistence: indexedDBLocalPersistence })
  : getAuth(app);

// Initialiser messaging uniquement pour le web
let messaging = null;
if (!isNative && 'serviceWorker' in navigator) {
  try {
    // Import dynamique uniquement quand nécessaire
    import('firebase/messaging').then(({ getMessaging }) => {
      messaging = getMessaging(app);
    });
  } catch (error) {
    console.log('Firebase Messaging non disponible ou non supporté');
  }
}

export { app, messaging };