// src/utils/routingState.ts

/** 
 * OnboardingStep : liste exhaustive des pages (en minuscules) 
 * qui représentent chacune une "étape". 
 */
export type OnboardingStep =
  | 'landing'
  | 'language'
  | 'heard-about'
  | 'why-learn'
  | 'level'
  | 'encouragement'
  | 'daily-goal'
  | 'notifications-opt-in'
  | 'start-choice'
  | 'scenario'; // signifie qu'onboarding terminé

interface RoutingState {
  onboardingStep: OnboardingStep;
}

/**
 * getRoutingState():
 *   - lit localStorage.routingState
 *   - renvoie { onboardingStep: "..." }
 */
export function getRoutingState(): RoutingState {
  const raw = localStorage.getItem('routingState');
  if (!raw) {
    // Valeur par défaut
    return { onboardingStep: 'landing' };
  }
  try {
    const parsed = JSON.parse(raw) as Partial<RoutingState>;
    return {
      onboardingStep: parsed.onboardingStep || 'landing',
    };
  } catch {
    // Reset si parse error
    return { onboardingStep: 'landing' };
  }
}

/**
 * setRoutingState():
 *   - met à jour localStorage.routingState
 *   - ex. setRoutingState({ onboardingStep: 'heard-about' })
 */
export function setRoutingState(state: Partial<RoutingState>) {
  console.log('[setRoutingState] => APPEL avec valeur:', state);
  console.log('[setRoutingState] => appelant =', new Error().stack);
  
  const currentState = getRoutingState();
  const newState = { ...currentState, ...state };
  localStorage.setItem('routingState', JSON.stringify(newState));
}