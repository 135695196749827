// src/components/MegaRouteGuard.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getRoutingState, setRoutingState } from '../utils/routingState';
import { allowedInterfaceLanguages, defaultInterfaceLanguage } from '../language/LanguageManager';
import { useUser } from '../context/UserContext';
import { Spinner } from '../components/Spinner';

export function MegaRouteGuard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, loading: authLoading } = useAuth();
  const { userData, loading: userDataLoading } = useUser();
  const [checking, setChecking] = useState(true);

  const { onboardingStep } = getRoutingState();
  const { lang } = useParams(); // ex: /:lang

  useEffect(() => {
    // On attend que AuthContext ET UserContext ne soient plus en loading
    if (authLoading || userDataLoading) {
      // on est encore en train de charger => on bloque la suite
      return;
    }

    // 1) Langue
    if (!lang || !allowedInterfaceLanguages.includes(lang)) {
      navigate(`/${defaultInterfaceLanguage}`, { replace: true });
      return;
    }

    // 2) S'il n'y a pas de user => AuthContext a fait signInAnonymously
    //    Donc currentUser devrait exister
    if (!currentUser) {
      console.log('[MegaRouteGuard] => Pas de currentUser => on laisse sur landing ?');
      // éventuellement navigate(`/${lang}/landing`);
      setChecking(false);
      return;
    }

    // 3) S'il y a un user => a-t-il fini l'onboarding ?
    const hasCompletedOnboarding = Boolean(
      userData?.lastSelectedStudyLanguage &&
      userData?.lastSelectedStudyLevel !== undefined
    );

    // 4) S'il est sur /login et a déjà complété l'onboarding => go /scenario
    if (location.pathname.includes('/login') && hasCompletedOnboarding) {
      console.log('[MegaRouteGuard] => User sur /login mais a déjà complété onboarding => go /scenario');
      setRoutingState({ onboardingStep: 'scenario' });
      navigate(`/${lang}/scenario`, { replace: true });
      return;
    }

    // 5) S'il a complété l'onboarding mais n'est pas sur une page compatible => go /scenario
    if (hasCompletedOnboarding && onboardingStep !== 'scenario') {
      console.log('[MegaRouteGuard] => User a complété onboarding mais n\'est pas sur scenario => redirection');
      setRoutingState({ onboardingStep: 'scenario' });
      navigate(`/${lang}/scenario`, { replace: true });
      return;
    }

    // 6) S'il n'a pas complété l'onboarding => laisser sur la page actuelle de l'onboarding
    if (!hasCompletedOnboarding) {
      console.log('[MegaRouteGuard] => User n\'a pas complété onboarding => on le laisse sur la page actuelle');
      setChecking(false);
      return;
    }

    // Sinon => on ne fait rien, on laisse passer
    setChecking(false);

  }, [authLoading, userDataLoading, currentUser, userData, onboardingStep, lang, location.pathname, navigate]);

  if (checking) {
    // Remplacer le texte par un spinner centré
    return (
      <div className="flex items-center justify-center h-screen">
        <div style={{ animation: 'fadeIn 0.3s ease-in-out 200ms both', opacity: 0 }}>
          <Spinner size={60} color="blue-500" />
        </div>
      </div>
    );
  }

  return <Outlet />;
}