// ---------------------------------------
// src/language/LanguageManager.ts
// ---------------------------------------
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { getRoutingState } from '../utils/routingState';

//----------------------------------------------------
// 1) Configuration des langues
//----------------------------------------------------
// Anciennement dans src/config/languages.ts
export const allowedInterfaceLanguages = [
  'en', 'fr', 'es', 'de', 'it', 'pt', 'ja', 'ko', 'zh', 'ru', 'ar', 'hi', 'ur', 'nl', 'pl', 'tr', 'id'
];

export const defaultInterfaceLanguage = 'en';

//----------------------------------------------------
// 2) convertLangToBCP47
//----------------------------------------------------
/**
 * Convertit le code de langue (ex: "fr", "en", "pt", etc.)
 * en un code BCP-47 pour la Web Speech API (ex: "fr-FR", "en-US", "pt-PT").
 */
export function convertLangToBCP47(lang: string): string {
  switch (lang) {
    case 'en':
      return 'en-US';
    case 'es':
      return 'es-ES';
    case 'fr':
      return 'fr-FR';
    case 'de':
      return 'de-DE';
    case 'it':
      return 'it-IT';
    case 'pt':
      return 'pt-PT';
    case 'nl':
      return 'nl-NL';
    case 'zh':
      return 'zh-CN';
    case 'ar':
      return 'ar-SA';
    case 'hi':
      return 'hi-IN';
    case 'ru':
      return 'ru-RU';
    case 'ko':
      return 'ko-KR';
    case 'id':
      return 'id-ID';
    case 'tr':
      return 'tr-TR';
    case 'pl':
      return 'pl-PL';
    case 'ja':
      return 'ja-JP';
    case 'ur':
      return 'ur-PK';
    default:
      // Par défaut, on renvoie l'anglais US si la langue n'est pas gérée
      return 'en-US';
  }
}

//----------------------------------------------------
// 3) useUserLang
//----------------------------------------------------
/**
 * Petit hook qui retourne la langue d'interface courante
 * (ou 'en' par défaut).
 */
export function useUserLang(): string {
  const { interfaceLanguage } = useLanguage();
  return interfaceLanguage || 'en';
}

//----------------------------------------------------
// 4) LanglessRedirect
//----------------------------------------------------
/**
 * Composant qui redirige une route dépourvue de /:lang
 * vers /:lang/... (ex: "/contact" => "/fr/contact").
 */
type LanglessRedirectProps = {
  to: string; // ex: "contact", "about", etc.
};

export function LanglessRedirect({ to }: LanglessRedirectProps) {
  const navigate = useNavigate();
  const userLang = useUserLang();

  useEffect(() => {
    // Exemple: "/contact" => "/fr/contact"
    navigate(`/${userLang}/${to}`, { replace: true });
  }, [userLang, to, navigate]);

  return null;
}

//----------------------------------------------------
// 5) AutoLangWorkflowRedirect
//----------------------------------------------------
/**
 * Composant qui redirige automatiquement selon la langue
 * + l'étape de workflow stockée en localStorage.
 * 
 * - On lit userLang pour déterminer /:lang
 * - On lit `getRoutingState()` pour récupérer l'étape. 
 * - On redirige vers /:lang/:step
 */
export function AutoLangWorkflowRedirect() {
  const navigate = useNavigate();
  const userLang = useUserLang();

  useEffect(() => {
    // Vérifie si userLang est supportée
    const targetLang = allowedInterfaceLanguages.includes(userLang)
      ? userLang
      : defaultInterfaceLanguage;

    // On suppose que vous stockez l'étape d'onboarding dans localStorage
    // ex: { onboardingStep: 'language' | 'heard-about' | ... }
    const { onboardingStep } = getRoutingState(); 
    // Ajustez selon votre structure (vous pouvez renommer ou changer la logique)

    // Mapping "step" => segment d'URL
    const stepMapping: Record<string, string> = {
      language: 'language',
      heardAbout: 'heard-about',
      whyLearn: 'why-learn',
      encouragement: 'encouragement',
      dailyGoal: 'daily-goal',
      startChoice: 'start-choice',
      level: 'level',
      scenario: 'scenario',
      console: 'console',
    };

    // Par défaut => on va à /:lang
    let targetUrl = `/${targetLang}`;

    // Si on a une étape particulière et qu'elle est valide :
    if (onboardingStep && onboardingStep !== 'landing' && stepMapping[onboardingStep]) {
      targetUrl += `/${stepMapping[onboardingStep]}`;
    }

    navigate(targetUrl, { replace: true });
  }, [userLang, navigate]);

  return null;
}