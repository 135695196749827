  // src/services/userService.ts
  import { getApiUrl } from '../config/apiUrl';

  /**
   * Met à jour les paramètres utilisateur dans la base de données
   * @param uid Identifiant de l'utilisateur
   * @param updates Objet contenant les champs à mettre à jour
   * @returns Les données utilisateur complètes après mise à jour
   */
  export const updateUserSettings = async (
    uid: string,
    updates: Record<string, any>,
  ): Promise<any> => {
    try {
      const apiUrl = await getApiUrl();
      const response = await fetch(`${apiUrl}/api/user/updateUserInfo`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, ...updates }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update settings: ${response.status}`);
      }

      const data = await response.json();
      
      // Important: on retourne les données complètes incluant user
      return data;
    } catch (error) {
      console.error('Error updating settings:', error);
      throw error;
    }
  };

  /**
   * Supprime définitivement le compte utilisateur et toutes ses données
   * Cette opération est irréversible et conforme au RGPD
   * @param uid Identifiant de l'utilisateur à supprimer
   */
  export const deleteUserAccount = async (uid: string): Promise<void> => {
    try {
      const apiUrl = await getApiUrl();
      const response = await fetch(`${apiUrl}/api/user/delete-account`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ uid })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Failed to delete account: ${response.status}`);
      }
      
      // Si la réponse est vide, on renvoie simplement undefined
      return response.json().catch(() => undefined);
    } catch (error) {
      console.error('Error deleting account:', error);
      throw error;
    }
  };

  /**
   * Supprime toutes les conversations de l'utilisateur (sans supprimer son compte).
   * @param uid Identifiant de l'utilisateur
   */
  export const deleteAllConversations = async (uid: string): Promise<void> => {
    try {
      const apiUrl = await getApiUrl();
      const response = await fetch(`${apiUrl}/api/user/delete-all-conversations`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Unable to delete conversations (code ${response.status})`);
      }
    } catch (error) {
      console.error('[userService] deleteAllConversations =>', error);
      throw error;
    }
  };

  // Alias pour compatibilité avec le code existant si nécessaire
  export const deleteAccount = deleteUserAccount;