// src/context/AuthContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  User,
  onAuthStateChanged,
  signInAnonymously
} from 'firebase/auth';
import { auth } from '../firebaseConfig';
// Supprimer l'import Capacitor s'il n'est pas utilisé (le bloc iOS était commenté)
// import { Capacitor } from '@capacitor/core';
import { getApiUrl } from '../config/apiUrl';
import { deleteUserAccount } from '../services/userService';
import { logger } from '../utils/logger'; // Adapter chemin

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  deleteAccount: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  loading: true,
  deleteAccount: async () => {},
});

/**
 * Variable pour éviter d'appeler updateUserInfo
 * trop souvent en un court laps de temps.
 */
let lastUpdateCallTimestamp = 0;

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  logger.info('[AuthContext] *** COMPONENT MOUNTED ***'); 

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  // ==========================================================
  // (Bloc iOS signOut) - désactivé pour conserver la session
  // ==========================================================
  /*
  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      auth.signOut()
        .then(() => {
          console.log('[AuthContext] Forcing signOut on iOS to clear any stale tokens');
        })
        .catch((error) => {
          console.error('[AuthContext] Error forcing signOut on iOS:', error);
        });
    }
  }, []);
  */

  // ----------------------------------------------------------
  // A) Fonction updateUserInfo (inchangée pour l'instant, mais sera appelée plus judicieusement)
  // ----------------------------------------------------------
  const updateUserInfo = useCallback(async (
      uid: string,
      interfaceLang: string,
      timezone: string,
      preferredReminderHour: number
    ) => {
      try {
        const apiUrl = await getApiUrl();
        const now = Date.now();
        const msSinceLastCall = now - lastUpdateCallTimestamp;
        lastUpdateCallTimestamp = now;

        console.log('---------------------------------------------------');
        console.log('[AuthContext] updateUserInfo() CALLED =>', {
          uid: uid || 'UNDEFINED!',
          interfaceLang,
          timezone,
          preferredReminderHour,
          msSinceLastCall,
          now: new Date().toISOString(),
        });
        console.log('---------------------------------------------------');

        const payload = {
          uid,
          interfaceLanguage: interfaceLang,
          timezone,
          preferredReminderHour
        };

        console.log('[AuthContext] => [DEBUG] Payload exact envoyé à /updateUserInfo:', JSON.stringify(payload, null, 2));

        const headers = {
          'Content-Type': 'application/json',
          'Accept-Language': interfaceLang,
        };
        console.log('[AuthContext] => [DEBUG] Headers envoyés:', headers);

        console.log(`[AuthContext] => [DEBUG] Démarrage fetch vers ${apiUrl}/api/user/updateUserInfo`);
        const response = await fetch(`${apiUrl}/api/user/updateUserInfo`, {
          method: 'POST',
          headers,
          body: JSON.stringify(payload),
        });

        console.log('[AuthContext] => [DEBUG] Statut de la réponse:', response.status, response.statusText);

        if (!response.ok) {
          console.error('[AuthContext] => [DEBUG] Échec de la réponse:', { status: response.status, statusText: response.statusText, url: response.url });
          console.error('[AuthContext] ❌ Échec de updateUserInfo :', { status: response.status, statusText: response.statusText });
        } else {
          console.log('[AuthContext] => ✅ User info updated via /updateUserInfo');
          try {
            const data = await response.json();
            console.log('[AuthContext] => [DEBUG] Réponse complète:', data);
            console.log('[AuthContext] => Réponse du serveur:', data);
            
            // Utiliser les données utilisateur si disponibles
            if (data.user) {
              // Ici, vous aurez besoin d'une référence à setUserDataDirectly
              // Une option est d'injecter cette fonction via useContext ou props
            }
          } catch (parseErr) {
            console.warn('[AuthContext] => [DEBUG] Impossible de parser la réponse JSON:', parseErr);
            console.warn('[AuthContext] Impossible de parser la réponse JSON:', parseErr);
          }
        }
      } catch (error) {
        console.error('[AuthContext] => [DEBUG] Erreur complète dans updateUserInfo:', error);
        console.error('[AuthContext] ❌ updateUserInfo => catch error:', error);
      }
  }, []); // useCallback pour la stabilité de la référence

  // D) Listener onAuthStateChanged (simplifié)
  useEffect(() => {
    logger.info('[AuthContext] => Starting onAuthStateChanged watch');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      logger.debug('[AuthContext] onAuthStateChanged => user UID:', user?.uid || null, `| isAnonymous: ${user?.isAnonymous}`);
      logger.debug('[AuthContext] onAuthStateChanged => détails user:', {
        uid: user?.uid || null,
        email: user?.email || null,
        isAnonymous: user?.isAnonymous,
        providerData: user?.providerData || null,
        metadata: user?.metadata || null
      });

      if (!user) {
        // --- Cas 1: Aucun utilisateur détecté ---
        logger.info('[AuthContext] => No user detected. Attempting anonymous sign-in.');
        try {
          setLoading(true); // Mettre loading à true PENDANT l'attente de l'anonyme
          const startTime = Date.now();
          logger.debug('[AuthContext] => signInAnonymously DÉBUT:', new Date().toISOString());
          
          const anonResult = await signInAnonymously(auth);
          const elapsedTime = Date.now() - startTime;
          const anonUser = anonResult.user;
          
          logger.info('[AuthContext] ✅ Anonymous sign-in successful =>', anonUser.uid, `(${elapsedTime}ms)`);
          logger.debug('[AuthContext] => Détails de l\'utilisateur anonyme:', {
            uid: anonUser.uid,
            createdAt: anonUser.metadata?.creationTime,
            lastSignInTime: anonUser.metadata?.lastSignInTime
          });
          
          setCurrentUser(anonUser); // Mettre à jour l'état
          setLoading(false);
        } catch (signInErr) {
          logger.error('[AuthContext] => Anonymous sign-in failed:', signInErr);
          logger.error('[AuthContext] => stack trace:', signInErr instanceof Error ? signInErr.stack : 'No stack available');
          setCurrentUser(null);
          setLoading(false);
        }
      } else {
        // --- Cas 2: Utilisateur existant détecté ---
        const providers = user.providerData.map(p => p.providerId).join(', ');
        logger.info(`[AuthContext] => Existing user detected: ${user.uid} (Anonymous: ${user.isAnonymous}, Providers: ${providers})`);
        setCurrentUser(user); // Mettre à jour l'état
        setLoading(false);
      }

    }, (error) => {
      logger.error('[AuthContext] => onAuthStateChanged ERROR:', error);
      logger.error('[AuthContext] => stack trace:', error instanceof Error ? error.stack : 'No stack available');
      setCurrentUser(null);
      setLoading(false);
    });

    return () => {
      logger.info('[AuthContext] => cleaning up onAuthStateChanged listener');
      unsubscribe();
    };
  }, []); // Plus de dépendances nécessaires ici

  // NOUVEAU useEffect pour appeler updateUserInfo quand currentUser change (et n'est pas null/loading)
  useEffect(() => {
    if (currentUser && !loading) {
        logger.debug(`[AuthContext] User state updated (UID: ${currentUser.uid}, Anonymous: ${currentUser.isAnonymous}). Calling updateUserInfo.`);
        const browserLang = navigator.language || 'en';
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
        logger.debug(`[AuthContext] Browser lang: ${browserLang}, Timezone: ${tz}`);
        updateUserInfo(currentUser.uid, browserLang, tz, 17); // Valeur par défaut pour reminder hour
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, loading]); // updateUserInfo est dans useCallback, stable

  // E) Observe currentUser => logs (inchangé)
  useEffect(() => {
    // Ne loguer que si ce n'est pas l'état initial de chargement
    if (!loading) {
        logger.debug('[AuthContext] >>> State finalized:', {
          userUid: currentUser?.uid ?? 'null',
          isAnonymous: currentUser?.isAnonymous ?? 'N/A',
          loadingState: loading,
        });
    }
  }, [currentUser, loading]);

  // F) deleteAccount (inchangé, ne dépendait pas de la liaison)
  const deleteAccount = useCallback(async () => {
    const userToDelete = currentUser; // Garder une référence avant signOut
    if (!userToDelete) return;

    logger.info(`[AuthContext] Attempting to delete account: ${userToDelete.uid}`); // Correction: info
    setLoading(true); // Indiquer le chargement
    try {
      await deleteUserAccount(userToDelete.uid); // Appel backend/Firebase
      localStorage.clear();
      await auth.signOut(); // Déconnexion Firebase
      logger.info(`[AuthContext] Account deletion process completed for ${userToDelete.uid}. State will update via onAuthStateChanged to anonymous.`); // Correction: info
      // onAuthStateChanged gérera la transition vers null puis vers un nouvel anonyme.
      // Pas besoin de gérer isMultiProviderLinkingInProgress ici, signOut le fera sortir.
      // setLoading sera remis à false par onAuthStateChanged.
    } catch (error) {
      logger.error('[AuthContext] => Error during account deletion:', error);
      setLoading(false); // Arrêter le chargement en cas d'erreur ici
      // L'utilisateur pourrait être toujours connecté si deleteUserAccount a échoué.
      throw error; // Relancer pour que l'UI puisse réagir
    }
  }, [currentUser]); // Dépend seulement de currentUser


  return (
    <AuthContext.Provider value={{
      currentUser,
      loading,
      deleteAccount,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personnalisé inchangé
export const useAuth = () => useContext(AuthContext);