// src/App.tsx

import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { StartupRouter } from './components/StartupRouter';
import { ScrollToTop } from './components/routing/ScrollToTop';
import { Spinner } from './components/Spinner';
import './App.scss';
import { StripeService } from './services/StripeService';
import { Capacitor } from '@capacitor/core';
import { getApiUrl } from './config/apiUrl';

// ===== Ajout RevenueCat =====
import { initRevenueCat } from './services/RevenueCatService';
import { useAuth } from './context/AuthContext';

// Imports critiques (gardés statiques)
import { InnerApp } from './pages/InnerApp';

// Imports avec lazy loading
const LanglessRedirect = lazy(() => import('./language/LanguageManager').then(module => ({ default: module.LanglessRedirect })));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const DemoScenarioOverlayPage = lazy(() => import('./pages/DemoScenarioOverlayPage'));
const DemoStreakOverlayPage = lazy(() => import('./pages/DemoStreakOverlayPage'));
const DemoStreakObjectiveOverlayPage = lazy(() => import('./pages/DemoStreakObjectiveOverlayPage'));
const DemoContinueOrSwitchOverlayPage = lazy(() => import('./pages/DemoContinueOrSwitchOverlayPage'));
const DemoSpinnersPage = lazy(() => import('./pages/DemoSpinnersPage'));
const TestMicrophone = lazy(() => import('./components/TestMicrophone').then(module => ({ default: module.TestMicrophone })));
const DemoToastShowcasePage = lazy(() => import('./pages/DemoToastShowcasePage'));
const PaywallOffer = lazy(() => import('./pages/PaywallOffer'));
const SuccessPage = lazy(() => import('./pages/SuccessPage'));
const RegisterEmailForm = lazy(() => import('./pages/RegisterEmailForm'));
const VoxOverlay = lazy(() => import('./components/VoxOverlay'));
const DemoRegisterOverlayPage = lazy(() => import('./pages/DemoRegisterOverlayPage'));
const DemoPromoBars = lazy(() => import('./pages/DemoPromoBars'));
const TrialEndReminderPage = lazy(() => import('./pages/TrialEndReminderPage'));
const PlanSelectionPage = lazy(() => import('./pages/PlanSelectionPage'));

export default function App() {
  const { currentUser } = useAuth();

  useEffect(() => {
    // Initialisation de Stripe au démarrage de l'application (pour le web)
    StripeService.initialize();
  }, []);

  useEffect(() => {
    console.log('===== ENVIRONNEMENT =====');
    console.log('NODE_ENV:', process.env.NODE_ENV);
    console.log('========================');
  }, []);

  useEffect(() => {
    // Si l'app tourne sur une plateforme native, initialise RevenueCat
    if (Capacitor.isNativePlatform()) {
      initRevenueCat(currentUser?.uid);
    }
  }, [currentUser]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      // Fonction d'auto-diagnostic pour TestFlight
      async function runDiagnostic() {
        try {
          const platform = Capacitor.getPlatform();
          const apiUrl = await getApiUrl();
          const env = process.env.NODE_ENV || 'unknown';
          const isTestFlight = navigator.userAgent.includes('TestFlight');
          
          console.log('[TestFlight Diagnostic] Environnement:', {
            platform,
            apiUrl,
            env,
            isTestFlight,
            timestamp: new Date().toISOString()
          });
          
          console.log('[TestFlight Diagnostic] Tentative de connexion à:', apiUrl);
          
          try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 10000); // 10s timeout
            
            const response = await fetch(`${apiUrl}/api/ping`, {
              method: 'GET',
              headers: { 'X-Client-Source': 'ios-testflight-diagnostic' },
              signal: controller.signal
            });
            
            clearTimeout(timeoutId);
            
            if (response.ok) {
              const data = await response.json();
              console.log('[TestFlight Diagnostic] Connexion réussie:', data);
            } else {
              console.error('[TestFlight Diagnostic] Échec de la réponse:', response.status);
            }
          } catch (netError) {
            console.error('[TestFlight Diagnostic] Erreur réseau:', netError);
            try {
              console.log('[TestFlight Diagnostic] Tentative de connexion à google.com');
              await fetch('https://www.google.com');
              console.log('[TestFlight Diagnostic] Connexion à google.com réussie');
            } catch (googleError) {
              console.error('[TestFlight Diagnostic] Impossible de se connecter à google.com:', googleError);
            }
          }
        } catch (error) {
          console.error('[TestFlight Diagnostic] Erreur globale:', error);
        }
      }
      
      runDiagnostic();
    }
  }, []);

  return (
    <Router>
      <ScrollToTop />

      <div className="min-h-screen w-full">
        <Suspense fallback={
          <div className="flex items-center justify-center h-screen">
            <div style={{ animation: 'fadeIn 0.3s ease-in-out 200ms both', opacity: 0 }}>
              <Spinner size={60} color="blue-500" />
            </div>
          </div>
        }>
          <Routes>
            {/* Route racine => StartupRouter */}
            <Route path="/" element={<StartupRouter />} />

            {/* /login */}
            <Route path="/login" element={<LanglessRedirect to="login" />} />

            {/* Redirections langless */}
            <Route path="/about" element={<LanglessRedirect to="about" />} />
            <Route path="/mission-vision" element={<LanglessRedirect to="mission-vision" />} />
            <Route path="/how-it-works" element={<LanglessRedirect to="how-it-works" />} />
            <Route path="/contact" element={<LanglessRedirect to="contact" />} />
            <Route path="/pricing" element={<LanglessRedirect to="pricing" />} />
            <Route path="/privacy-policy" element={<LanglessRedirect to="privacy-policy" />} />
            <Route path="/settings" element={<LanglessRedirect to="settings" />} />
            <Route path="/success" element={<LanglessRedirect to="success" />} />
            <Route path="/team" element={<LanglessRedirect to="team" />} />

            {/* Démo spinners */}
            <Route path="/demo/spinners" element={<DemoSpinnersPage />} />

            {/* Démo overlays */}
            <Route path="/demo/scenario-overlay" element={<DemoScenarioOverlayPage />} />
            <Route path="/demo/streak" element={<DemoStreakOverlayPage />} />
            <Route path="/demo/streak-objective" element={<DemoStreakObjectiveOverlayPage />} />
            <Route path="/demo/continue-or-switch" element={<DemoContinueOrSwitchOverlayPage />} />

            {/* Démo microphone */}
            <Route path="/demo/microphone" element={<TestMicrophone />} />

            {/* Démo toasts */}
            <Route path="/demo/toast-showcase" element={<DemoToastShowcasePage />} />

            {/* Démo paywall */}
            <Route path="/demo/paywall-offer" element={<PaywallOffer />} />

            {/* Démo register overlay */}
            <Route path="/demo/register-overlay" element={<DemoRegisterOverlayPage />} />

            {/* Démo promo bars */}
            <Route path="/demo/promo-bars" element={<DemoPromoBars />} />

            {/* Démo success page */}
            <Route path="/demo/success-page" element={<SuccessPage isDemo={true} />} />

            {/* Route /register (inscription via overlay) */}
            <Route
              path="/register"
              element={
                <VoxOverlay
                  isOpen={true}
                  dialogSize="md"
                  onClose={() => {
                    console.log("[App] => Overlay /register: onClose() => go back");
                    window.history.back();
                  }}
                >
                  <RegisterEmailForm
                    onSuccess={() => {
                      console.log('Inscription réussie ! [route /register]');
                    }}
                  />
                </VoxOverlay>
              }
            />

            {/* Route pour la confirmation d'essai gratuit */}
            <Route path="/free-trial-reminder" element={<TrialEndReminderPage />} />

            {/* Route pour la sélection de plan */}
            <Route path="/plan-selection" element={<PlanSelectionPage />} />

            {/* Route dynamique pour l'application interne */}
            <Route path="/:lang/*" element={<InnerApp />} />

            {/* Landing page */}
            <Route path="/landing" element={<LandingPage />} />

            {/* 404 - Page non trouvée */}
            <Route
              path="*"
              element={
                <div className="p-4 text-center">
                  <h1 className="text-2xl font-bold">404 - Not Found</h1>
                  <p>Oops, this page does not exist.</p>
                </div>
              }
            />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}