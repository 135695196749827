// src/pages/InnerApp.tsx

import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useParams, useNavigate, Routes, Route } from 'react-router-dom';
import i18n from '../i18n';
import { Spinner } from '../components/Spinner';

// Hooks & context (imports essentiels à garder)
import { useAppInitialization } from '../hooks/useAppInitialization';
import { useStudy } from '../context/StudyContext';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import { allowedInterfaceLanguages, defaultInterfaceLanguage } from '../language/LanguageManager';
import { WorkflowStep, useWorkflowPreloading } from '../workflow/WorkflowManager';
import { setRoutingState } from '../utils/routingState';
import { useWarnOnUnsavedChanges } from '../hooks/useWarnOnUnsavedChanges';
import { MegaRouteGuard } from '../components/MegaRouteGuard';

// Pages marketing avec lazy loading
const AboutPage = lazy(() => import('./Marketing/AboutPage'));
const MissionVisionPage = lazy(() => import('./Marketing/MissionVisionPage'));
const HowItWorksPage = lazy(() => import('./Marketing/HowItWorksPage'));
const ContactPage = lazy(() => import('./Marketing/ContactPage'));
const PricingPage = lazy(() => import('./Marketing/PricingPage'));
const TeamPage = lazy(() => import('./Marketing/TeamPage'));
const PrivacyPolicyPage = lazy(() => import('./Marketing/PrivacyPolicyPage'));

// Pages d'onboarding avec lazy loading
const LandingPage = lazy(() => import('./LandingPage').then(module => ({ default: module.LandingPage })));
const StudyLanguageSelection = lazy(() => import('./StudyLanguageSelection').then(module => ({ default: module.StudyLanguageSelection })));
const StudyLevelSelection = lazy(() => import('./StudyLevelSelection').then(module => ({ default: module.StudyLevelSelection })));
const HeardAboutPage = lazy(() => import('./HeardAboutPage'));
const WhyLearnPage = lazy(() => import('./WhyLearnPage'));
const EncouragementPage = lazy(() => import('./EncouragementPage'));
const DailyGoalPage = lazy(() => import('./DailyGoalPage'));
const StartChoicePage = lazy(() => import('./StartChoicePage'));
const NotificationsOptInPage = lazy(() => import('./NotificationsOptInPage'));

// Pages scénario et console avec lazy loading
const ConversationScenarioSelection = lazy(() => import('./Scenarios/ScenarioSelection/ScenarioSelectionPage'));
const ScenarioDetailsPage = lazy(() => import('./Scenarios/ScenarioDetails/ScenarioDetailsPage').then(module => ({ default: module.ScenarioDetailsPage })));
const ScenarioPhrasesPage = lazy(() => import('./Scenarios/ScenarioDetails/ScenarioPhrasesPage').then(module => ({ default: module.ScenarioPhrasesPage })));
const ConsolePageContainer = lazy(() => import('./ConsolePage/ConsolePageContainer').then(module => ({ default: module.ConsolePageContainer })));

// Autres pages avec lazy loading
const SettingsPage = lazy(() => import('./SettingsPage').then(module => ({ default: module.SettingsPage })));
const SuccessPage = lazy(() => import('./SuccessPage'));
const DeleteAccountPage = lazy(() => import('./DeleteAccountPage'));
const DeleteConversationsPage = lazy(() => import('./DeleteConversationsPage'));
const LoginPage = lazy(() => import('./LoginPage').then(module => ({ default: module.LoginPage })));

/**
 * Simule un appel API pour mettre à jour la langue préférée de l'utilisateur.
 */
async function updateUserPreferredLanguage(uid: string, lang: string) {
  console.log('[updateUserPreferredLanguage] => uid=', uid, '| lang=', lang);
  // Exemple : await fetch('/api/user/updatePreferredLanguage', { method: 'POST', body: JSON.stringify({ uid, lang }) });
}

export function InnerApp() {
  const [currentStep, setCurrentStep] = useState<WorkflowStep>('landing');

  // Récupération des paramètres d'URL
  const { lang } = useParams();
  const navigate = useNavigate();

  // Contextes
  const { interfaceLanguage, setInterfaceLanguage } = useLanguage();
  const { currentUser } = useAuth();
  const { studyLanguage, setStudyLanguage } = useStudy();

  // Avertissements sur les changements non sauvegardés
  useWarnOnUnsavedChanges();

  // Initialisation de l'application
  useAppInitialization({
    currentStep,
    setCurrentStep,
    locationPathname: window.location.pathname,
  });

  // Préchargement en fonction de l'étape courante (images, etc.)
  useWorkflowPreloading(currentStep);

  // Gestion de la langue d'interface
  useEffect(() => {
    if (!lang) return;
    if (!allowedInterfaceLanguages.includes(lang)) {
      navigate(`/${defaultInterfaceLanguage}`, { replace: true });
      return;
    }
    if (lang !== interfaceLanguage) {
      setInterfaceLanguage(lang);
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;

      if (currentUser) {
        updateUserPreferredLanguage(currentUser.uid, lang);
      }
    } else {
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;
    }
  }, [lang, interfaceLanguage, currentUser, setInterfaceLanguage, navigate]);

  // Récupération de studyLanguage depuis le localStorage
  useEffect(() => {
    if (!studyLanguage) {
      const stored = localStorage.getItem('studyLanguage');
      if (stored) {
        setStudyLanguage(stored);
      }
    }
  }, [studyLanguage, setStudyLanguage]);

  // Handlers d'onboarding
  const handleGetStarted = () => {
    if (studyLanguage) {
      setRoutingState({ onboardingStep: 'heard-about' });
      navigate(`/${lang || defaultInterfaceLanguage}/heard-about`);
    } else {
      setRoutingState({ onboardingStep: 'language' });
      navigate(`/${lang || defaultInterfaceLanguage}/language`);
    }
  };

  const handleStudyLanguageSelect = () => {
    setRoutingState({ onboardingStep: 'heard-about' });
    navigate(`/${lang || defaultInterfaceLanguage}/heard-about`);
  };

  const handleHeardAboutNext = () => {
    setRoutingState({ onboardingStep: 'why-learn' });
    navigate(`/${lang || defaultInterfaceLanguage}/why-learn`);
  };

  const handleWhyLearnNext = () => {
    setRoutingState({ onboardingStep: 'level' });
    navigate(`/${lang || defaultInterfaceLanguage}/level`);
  };

  const handleStudyLevelSelect = () => {
    setRoutingState({ onboardingStep: 'encouragement' });
    navigate(`/${lang || defaultInterfaceLanguage}/encouragement`);
  };

  const handleEncouragementNext = () => {
    setRoutingState({ onboardingStep: 'daily-goal' });
    navigate(`/${lang || defaultInterfaceLanguage}/daily-goal`);
  };

  const handleDailyGoalNext = () => {
    setRoutingState({ onboardingStep: 'notifications-opt-in' });
    navigate(`/${lang || defaultInterfaceLanguage}/notifications-opt-in`);
  };

  const handleNotificationsOptInNext = () => {
    setRoutingState({ onboardingStep: 'start-choice' });
    navigate(`/${lang || defaultInterfaceLanguage}/start-choice`);
  };

  const handleStartChoiceNext = () => {
    setRoutingState({ onboardingStep: 'scenario' });
    navigate(`/${lang || defaultInterfaceLanguage}/scenario`);
  };

  // Handlers scénario / console
  const handleScenarioSelect = (scenarioId: string) => {
    navigate(`/${lang || defaultInterfaceLanguage}/scenario/${scenarioId}`);
  };

  const handleExitConsole = () => {
    navigate(`/${lang || defaultInterfaceLanguage}/scenario`);
  };

  return (
    <Suspense fallback={
      <div className="flex items-center justify-center h-screen">
        <div style={{ animation: 'fadeIn 0.3s ease-in-out 200ms both', opacity: 0 }}>
          <Spinner size={60} color="blue-500" />
        </div>
      </div>
    }>
      <Routes>
        <Route element={<MegaRouteGuard />}>
          {/* (1) Pages marketing */}
          <Route path="about" element={<AboutPage />} />
          <Route path="mission-vision" element={<MissionVisionPage />} />
          <Route path="how-it-works" element={<HowItWorksPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />

          {/* Route de login */}
          <Route path="login" element={<LoginPage />} />

          {/* (2) Workflow d'onboarding */}
          <Route index element={<LandingPage onGetStarted={handleGetStarted} />} />
          <Route path="language" element={<StudyLanguageSelection onLanguageSelect={handleStudyLanguageSelect} />} />
          <Route path="heard-about" element={<HeardAboutPage onNext={handleHeardAboutNext} />} />
          <Route path="why-learn" element={<WhyLearnPage onNext={handleWhyLearnNext} />} />
          <Route path="level" element={<StudyLevelSelection onLevelSelect={handleStudyLevelSelect} />} />
          <Route path="encouragement" element={<EncouragementPage onNext={handleEncouragementNext} />} />
          <Route path="daily-goal" element={<DailyGoalPage onNext={handleDailyGoalNext} />} />
          <Route path="notifications-opt-in" element={<NotificationsOptInPage onNext={handleNotificationsOptInNext} />} />
          <Route path="start-choice" element={<StartChoicePage onNext={handleStartChoiceNext} />} />

          {/* (3) Scénarios et console */}
          <Route path="scenario" element={<ConversationScenarioSelection onScenarioSelect={handleScenarioSelect} />} />
          <Route path="scenario/:scenarioId" element={<ScenarioDetailsPage />} />
          <Route path="scenario/:scenarioId/phrases" element={<ScenarioPhrasesPage />} />
          <Route path="console" element={<ConsolePageContainer onExit={handleExitConsole} />} />

          {/* (4) Autres pages */}
          <Route path="settings" element={<SettingsPage />} />
          <Route path="delete-account" element={<DeleteAccountPage />} />
          <Route path="delete-conversations" element={<DeleteConversationsPage />} />
          <Route path="success" element={<SuccessPage />} />

          {/* (5) Landing spécifique */}
          <Route path=":studyLang/:verbSlug" element={<LandingPage onGetStarted={handleGetStarted} />} />

          {/* (6) 404 */}
          <Route path="*" element={<div className="p-4 text-center">
            <h1 className="text-2xl font-bold">404 - Not Found (InnerApp)</h1>
            <p>Oops, this page doesn't exist.</p>
          </div>} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default InnerApp;